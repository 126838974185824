.video{
    max-width: 1900px;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
}

.content{
    width: 88%;
    margin: 0 auto;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 60% 37%;
    padding:  0;
}

.video_con{
    width: 100%;
}

.video_con iframe{
    height: 25em;
}

.scroll_con{
    border: 1px solid #000;
    color: #000;
    padding:  0;
}

.list_con{
    overflow-y: scroll;
    height: 25em;
}

.videoRow {
    display: grid;
    grid-template-columns: 30% 60%;
    align-items: center;
    border-radius: 1em;
    padding: 0.5em 0 0.5em 0.5em;
    cursor: pointer;
}
  
.videoRow_selected {
  display: grid;
  grid-template-columns: 30% 60%;
  align-items: center;
  padding: 0.5em 0 0.5em 0.5em;
  cursor: pointer;
  background-color: #8A8A8A;
}

  .line{
    width: 96%;
    margin: 0 auto;
    border-bottom: 0.1px solid #fff;
  }
  
  .smallVideo {
    margin-right: 1em;
  }

  .smallVideo img{
    width: 100%;
    height: 6em;
  }
  
  .videoDetails {
    height: fit-content;
    margin-top: -.5em;
    font-family: Montserrat;
    color: #000;
    font-size: calc(0.6rem + 0.3vw);
  }
  
  /* Example hover effect */
  /* .smallVideo:hover {
    transform: scale(1.1); 
    transition: transform 0.3s ease;
  } */

  @media screen and (max-width:750px) {
    .content{
        grid-template-columns: 1fr;
        row-gap: 1em;
        padding: 0em 0;
    }
    
  .smallVideo img{
    height: 4em;
  }
    
.video_con iframe{
    height: 13em;
}

.list_con{
    height: 18em;
}

.videoDetails {
    /* flex: 1; */
    font-family: Montserrat;
    font-size: calc(0.5rem + 0.2vw);
  }
  }