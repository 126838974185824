.btn{
  width: 3em !important;
  height: 2.5em;
  border-radius:.4em;
  background: #000;
  border:none;
  cursor: pointer;
  /* height:100%; */
  color:#fff;
  padding: 0 1em;
  transition: background-color 0.5s ease, color 1s ease;
  margin: 0 0 0 auto;
}

/* .btn:hover{
  background:blue;
  color:var(--secondary-color);
} */

.input{
  border: none !important;
  height: 100% !important;
}

  .input:focus{
    outline: none;
  }
  
 
.custom_select select:focus{
  outline:none ;
  border: none;
}

.custom_select select::after{
  background-color: blue;
  text-align: start;
}

.custom_select option::after{
 background-color: blue;
}

.custom_select_mob{
  width: 80% !important;
  margin-top: 1em !important;
  display: none;
  margin: auto;
  border-radius: 10px !important;
  background-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
}
