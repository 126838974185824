.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
      height: 100vh;
  
     /* background: url(../../../assets/jeep.png);  */
     background-color: #000;
    background-size: cover !important;
    background-repeat: no-repeat;

  }
 .cart {
    position: absolute;
    width: 50vmin;

      top: 50%;
      left: 50%; 
      transform: translate(-50%, -50%);
      text-align: center;
      background-color: white;
      padding: 1rem;
      border-radius: 5px;
      box-shadow: 0px 0px 25px rgb(255, 255, 255);
 }
 .logoContainer {
    width: 100%;
    align-items: center;
    text-align: center;
 }
 .logoo {
    width: 50%;
  pointer-events: none;
  
 }
 .validation{
    position: relative;
    width: 100% ;
    color: #000;
    padding-left: 10%;
    text-align: start !important;
 }
 .formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 15%;
    padding-bottom: 10%;
 }
 .buttonn{
    position: relative;
    width: 70%;
    margin-top: 7% !important;
    margin-bottom: 10% !important;
    background-color: #000 !important;
 }
 .inputt {
    position: relative;
    width: 80%;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
 }