.home {
  width: 90%;
  max-width: 40rem;
  padding: 3rem;
  margin: 2rem auto;
  text-align: center;
}
.cssssss {
  background-color: #000;
  color: white;
}

.sidebar{
  background-color: #000 !important;
  color: white;
  height: 100%;
}
.sidebartop{
  background-color: white  !important;
}

.sidebartop1{
  background-color: #000  !important;
  color: white;
}
.sidebar a{
  color: white;
}

.icons {
  color: white;
}
.iconsSelected {
  color: black;
}

.arrow{
  color:black;
}

.overlay {
  background-color:#fff  ;
  color: black !important;
  opacity: 0.7;
}

.overlay1 {
  position: relative;
  width: 10px !important;
  left: 0%;
  background-color:#fff  ;
}
.logooo{
  width: 100%;
  margin: auto;
}
.test{
  position: absolute;
  width: fit-content;
  top: 50%;
  left: 55%; 
  transform: translate(-50%, -50%);
}