.navbar{
    width: 100%;
    max-width: 1900px;
    margin: 0 auto !important;
    height: fit-content;
    padding-top: 0;
    position: fixed;
    background-color: #fff;
    z-index: 999;
    align-self: center;
    justify-self:center;
}

.header{
    width: 100%;
    padding: .8em 0;
    background-color: #000;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.header p{
    width: 70%;
    color: #fff;
    font-family: Raleway;
    font-style: normal;
    margin: auto;
    font-size: calc(0.6rem + 0.2vw);
    text-align: center;
}

.header_close{
    position: absolute;
    top: 0.4em;
    right: 5em;
    color: #fff;
}

.long_line{
    border-left: 1px solid blue;
    height: 2em;
}


.long_line_fixed{
    border-left: 1px solid #fff;
    height: 2em;
}

.nav_head{
    width: 88%;
    height: fit-content;
    display: grid;
    grid-template-columns: 40% 20% 40%;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1em 0;
}

.search{
    margin: auto 0;
}

.logo{
    display: none;
    margin: auto  !important;
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
}

.logo img{
    width: 50%;
    margin: auto !important;
    padding-left: 25%;
}

.LogoDesktop{
    margin: auto  !important;
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
}

.LogoDesktop img{
    width: 100%;
    margin: auto !important;
}


.user{
    width: fit-content;
    margin: auto 0 auto auto !important;
    display: flex;
    flex-direction: row;
    column-gap: 0.4em;
    text-align: center;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size:calc(0.7rem + 0.2vw) ;
    transition:  color .5s ease;
}



.user_fixed{
    display: flex;
    flex-direction: row;
    column-gap: 0.4em;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.user p{
    font-style: normal;
    font-weight: 400;
    font-size:calc(0.7rem + 0.2vw) ;
}
.user:hover{
    color: red !important;
}

.cart_icon{
    width: 1em;
    height: 1em;
}

.nav{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.navs_link{
    width: fit-content;
    max-width: 88%;
    margin: auto;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    text-decoration: none;
}

.navs_link p{
    text-decoration: none !important;
    font-family: Raleway;
    font-style: normal;
    color: #000;
    cursor: pointer;
    height: 100%;
    font-size: alc(0.7rem + 0.2vw);
    font-weight: 600;
    transition: fill 0.5s ease, color 0.3s ease;
}

    .navs_link p:hover{
    color: red;
    }

.fixedlogo{
    margin: auto;
}

.mobile_nav{
    display: none;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em 2em !important;
}

.mobile_nav img{
    width: 7em;
}

.mobile_nav_fixed{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: none;
    width: 90%;
    margin: 0 auto;
    border-radius: 1em;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em 1em !important;
}

.mobile_nav_fixed img{
    width: 10% !important;
}

.mob_icon{
    width: 1.5em !important;
    height: 1.5em !important;
    margin: auto 0;
    color: #000;
}

.acc_menu{
    border-radius: 2em !important;
    padding: 0 0em !important;
}

@media screen and (max-width:950px) {
    .mobile_nav{
        display: flex;
    }

    .nav_head{
        display: none;
    }

    .nav{
        display: none;
    }
    .fixed_nav{
        display: none;
    }
    
.header_close{
    right: 3em;
}
.user{
    margin: auto 0 !important;
}

.logo{
    display: inline;
}

.LogoDesktop{
    display: none;
}

}


@media screen and (max-width:650px) {
    
    .mobile_nav img{
        width: 5em;
    }  

.mobile_nav_fixed img{
    width: 15% ;
}

.header_close{
    right: 1em;
}
}