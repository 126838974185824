.card{
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2% 4%;
    color: #000;
    margin: 1% 0 ;
    padding-bottom: 4%;
}

.imageCont{
    width: 6em;
    flex: 1;
}

.card p{
    margin: 0.4em 0;
}

.quantity{
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: calc(.7rem + 0.3vw);
    font-family: Raleway;
}


.cardmobile{
    display: none;
    grid-template-columns: 30% 70%;
    color: #000;
    margin: 5% 0 0% 0;
    border: 1px solid #D3DADC;
    border-radius: 20px;
}

.quantitymobile{
    width: 100%;
    display: none;
    flex-direction: row;
    margin-top: 1em;
    justify-content: space-between;
}

.infoCont{
    width: 100%;
    display: flex;
}

.delete_btn{
    width: fit-content;
    border: none;
    background-color: #fff;
    color: #000 ;
    transition: background-color 0.5s ease, color 1s ease;
    font-size: calc(.7rem + 0.3vw);
    border-radius: .5em;
    padding: 0.5em .6em;
    cursor: pointer;
    margin-left: auto;
}
.delete_btnMob{
    position: absolute;
    top: .5em;
    right:  .5em;
    display: none;
    width: fit-content;
    border: none;
    background-color: #fff;
    color: #000 ;
    font-size: calc(.7rem + 0.3vw);
    border-radius: .5em;
    padding: 0.5em .6em;
}



.dicription{
    width: 30em;
    color: #000;
    font-size: calc(.6rem + 0.2vw);
}

.quantitymobile{
    display: none;
    font-weight: 600;
    font-size: large;
}

@media screen and (max-width: 1400px) {
    .dicription{
        width: 17em;
        color: #000;
    }

}


@media screen and ( max-width: 650px) {
    .quantity{
      display: none;
    }
    .quantitymobile{
        display: flex;
    }
    .delete_btnMob{
        display: inline;
    } 
}