.heru{
    width: 100%;
    max-width: 1900px;
    margin: 15em auto 2em auto;
    height: fit-content;
}

.content{
    display: grid;
    width: 90%;
    grid-template-columns: 50% 50%;
    margin: 0 auto;
    justify-content: space-between;
}

.text{
    width: 80%;
    text-align: start;
    display: flex;
    flex-direction: column;
    row-gap: 0em;
    margin:0 auto;
}

.text h1{
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    color: #000;
    font-size: calc(1.7rem + .8vw);
    width: 100%;
}

.text p{
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    color: #000;
    font-size: calc(1.1rem + 0.3vw);
    margin-bottom: 2em;
}

.text button{
    width: fit-content;
    height: fit-content;
    padding: 1em 3.5em;
    cursor: pointer;
    color: #fff;
    background-color: #000;
    transition: background-color 0.5s ease, color 1s ease;
    border: .3em solid #000;
    border-radius: 5em 0; 
    font-size: calc(0.7rem + 0.2vw);
    font-weight: 600;
}

.text button:hover{
    background-color: #fff;
    color: #000;
}

.imageCont{
    width: 100%;
    height: fit-content;
  }
  
  
  .card_img{
    position: relative;
    width: 80%;
    margin: auto 0 auto auto;
    height: fit-content;
  }

  .card_img img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  

@media screen and (max-width:950px) {
    .heru{
        margin: 10em auto 0em auto;
    }
    .content{
        display: flex;
        flex-direction: column-reverse;
    }

    .text{
        width: 100%;
    }
    .text h1{
        margin-bottom: 0;
    }
    
    .text p{
        margin: .5em 0 1em 0;
    }
    .card_img{
        position: relative;
        width: 70%;
        margin: auto ;
        height: fit-content;
      }
}