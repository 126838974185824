.bigContainer{
    position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0 0 ;
    max-width: 1900px;
    margin: 0 auto;
  }

  .header{
    position: relative;
    width: 88%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
  }
  
  .border{
    position: relative;
    width: 50%;
    height: fit-content;
    border: 3px #000 solid;
    border-radius: 1em;
    margin: 0 auto;
  }
  
  .title{
    position: relative;
    width: fit-content;
    margin: auto;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(1.2rem + .3vw);
    text-align: center;
  }
  .title h1{
    font-size: calc(1.8rem + .5vw);
  }

  
.content{
  width: 100%;
  margin: auto;
  padding: 2em 0;
}

.slide {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  margin: 0 auto;
  width: 20em; /* Adjust width as needed */
  height: 100%; /* Ensure the slide takes up full height */
  text-align: center; /* Center the content within the slide */
}

.image_con{
  width: 100%;
  height: 100%;
}

.movearound_img{
  width: 100%;
  /* max-height: 25em !important; */
  object-fit: fill;
  justify-self: center !important;
  align-self: center !important;
}

.slide2{
  display: none;
  margin-top: 2em;
}

@media screen and (max-width:950px) {
  .companies{
      background-size: cover;
      margin-bottom: 2em;
  }
  .slide2{
      display: block;
  }
  
.slide{
  width: 8em;
}

  /* .content{
      padding: 7em 0;
  } */
  
.movearound_img{
  width: 100%;
}
  
}