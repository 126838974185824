.gravityContainer{
    width: 100%;
    max-width: 1900px;
    margin: 2em auto;
    height: fit-content;
}

.content{
    display: grid;
    width: 90%;
    grid-template-columns: 27% 40% 33%;
    margin: auto;
    justify-content: space-between;
}

.border{
    position: relative;
    width: 5em;
    height: fit-content;
    border: .17em #000 solid;
    border-radius: 1em;
    margin: 0;
  }
.text{
    width: 90%;
    text-align: start;
    display: flex;
    flex-direction: column;
    row-gap: 0em;
    margin: auto;
}

.text h1{
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    color: #000;
    font-size: calc(1rem + .3vw);
    width: 100%;
}

.text p{
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    color: #000;
    font-size: calc(.7rem + 0.2vw);
    margin-bottom: 2em;
    letter-spacing: .1em;
    line-height: 150%;
}

.text button{
    width: fit-content;
    height: fit-content;
    padding: .5em 2em;
    cursor: pointer;
    color: #fff;
    background-color: #000;
    transition: background-color 0.5s ease, color 1s ease;
    border: .1em solid #000;
    border-radius: 0 5em; 
    font-size: calc(0.7rem + 0.2vw);
    font-weight: 600;
}

.text button:hover{
    background-color: #fff;
    color: #000;
}

.imageCont{
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  
  
  .card_img{
    position: relative;
    width: 90%;
    margin: auto;
    height: fit-content;
  }

  .card_img img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .card_img1{
    position: relative;
    width: 90%;
    margin: auto;
    height: fit-content;
  }

  .card_img1 img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  

@media screen and (max-width:950px) {
    .gravityContainer{
        margin: 0em auto;
    }
    .card_img1{
        display: none;
      }
    .heru{
        margin: 0em auto 0em auto;
    }
    .content{
        display: flex;
        flex-direction: column;
    }
}