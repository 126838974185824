
.detailsContainer{
    position: relative;
    width: 88%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 2em 0 ;
    max-width: 1900px;
    margin: 0 auto;
  }

.bigContainer{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 3% 0;
}

.swiper{
  position: relative;
  width: 50%;
  display: flex;
  height: fit-content;
  flex-direction: column;
  margin: auto ;
}

.imageContainer{
  position: relative;
  width: 90%;
  max-height: 40em;
  height: 40vw;
  margin: auto auto auto 0;
  overflow: hidden;
  cursor:zoom-in;
}

.imageContainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.imageContainer:hover img{
  transform: scale(1.5);
}
 
.out_of_stock{
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(194, 194, 194, 0.5);
  z-index: 9;
  cursor: auto;
  cursor: pointer;
  }
  
  .out_of_stock p {
  width: fit-content;
  height: fit-content;
  margin: auto;
  font-family: Raleway;
  font-size: calc(1.3rem + 0.4vw);
  text-align: center;
  justify-self: center;
  align-self: center;
  color: #fff;
  padding: 0.2em 0.8em;
  border-radius: 5px;
  background-color: #111;
  }
.bookCoversContainer{
  position: relative;
  width: 45%;
  margin: 0 auto 0 0;
  height: fit-content;
  display: flex;
  flex-direction: row;
}

.bookCoversContainerMob{
  position: relative;
  width: 90%;
  display: none;
  margin: 0;
  height: fit-content;
  flex-direction: row;
  margin-top: .3em;
}

.bookCovers{
  position: relative;
  width: 20%;
  margin-right: 3%;
}
.bookCoverimg{ 
  width: 100%;
  height: 100%;
  object-fit: fill;
  border: .1em solid  #fff;
}
.bookCoverSelectedimg{ 
  width: 100%;
  height: 100%;
  object-fit: fill;
  border: .1em solid #000;
}

.details{
  flex: 1;
  border: .1em solid #000;
  display: flex ;
  flex-direction: column;
  position: relative;
  text-align: start;
  justify-content: space-between;
  font-size: calc(0.8rem + 0.2vw);
  font-family: Raleway;
  letter-spacing: .1em;
}
.addBtn{
  width: fit-content;
  height: fit-content;
  padding: .7em 3.5em;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  transition: background-color 0.5s ease, color 1s ease;
  border: .1em solid #000;
  border-radius: 5em 0; 
  font-size: calc(0.7rem + 0.2vw);
  font-weight: 600;
  margin: 0 0 0 auto;
}

.addBtn:hover{
  background-color: #fff;
  color: #000;
}
.quantity{
  width: 20%;
  height: fit-content;
  margin: auto 0;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  border: .1em solid #000;
  font-size: calc(.8rem + .3vw);
}



@media screen and (max-width:1050px) {
  .details{
    flex: 1;
    height: fit-content !important;
    padding-bottom: 1em;
  }
  .bookCoversContainer{
    display: none;
  }
  
  .bookCoversContainerMob{
    display: flex;
  }
  .quantity{
    width: 50%;
  }
}

@media screen and (max-width:950px) {
  .details{
    flex: 1;
    height: fit-content !important;
    padding-bottom: 1em;
  }
}

@media screen and (max-width:750px) {
  .detailsContainer{
    padding: 0;
  }
  .bigContainer{
    flex-direction: column;
    gap: 2em;
  }
  
  .swiper{
    width: 70%;
    margin: auto;
  }
  .imageContainer{
    position: relative;
    width: 100%;
    height: auto;
    margin: auto ;
    overflow: hidden;
    cursor:zoom-in;
  }
  .addBtn{
    margin: 1em 0 1em auto;
  }
}
@media screen and (max-width:550px) {
  .swiper{
    width: 90%;
    margin: auto;
  }
}
@media screen and (max-width:450px) {
  .swiper{
    width: 100%;
    margin: auto;
  }
}