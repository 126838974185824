.cart_container{
    width: 100%;
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    max-width: 1900px;
    margin: 13em auto 0 auto;
}

.header{
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}

.border{
  position: relative;
  width: 50%;
  height: fit-content;
  border: .1em #000 solid;
  border-radius: 1em;
}

.title{
  position: relative;
  width: fit-content;
  margin: auto;
  height: fit-content;
  font-family: Raleway;
  font-size: calc(1.2rem + .3vw);
  text-align: center;
}
.title h1{
  font-size: calc(1.8rem + .5vw);
}


.bigContainer{
    display: grid;
    width: 88%;
    margin: 2em auto;
    grid-template-columns: 50% 50%;
    height: fit-content;
    position: relative;
}

.billingDetails{
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 97%;
    padding-top: 3%;
}

.total_con{
    position: relative;
    width: 97%;
    display: flex;
    flex-direction: column;
    column-gap: 5%;
    margin: 0 0 1% auto;
    height: fit-content;
}
.totalCardCont{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 5%;
    height: fit-content;
    border: .1em solid #000;
}

.total{
  width: 100%;
  margin: 0 auto;
}

.totalrows{
  display: grid;
  width: 90%;
  grid-template-columns: repeat(2, 49%);
  text-align: start;
  color: #000;
  margin: auto;
  font-family: Raleway ;
  font-weight: 600;
  column-gap: 2%;
  font-size: calc(.8rem + 0.3vw);
}
.totalrows p{
  margin: 0.4em 0;
  align-self: center;
}
.noteContainer{
    width: 100%;
}
 .inputContainer{
  width: 100%;
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
 }
.subtitle{
  color: #000;
  font-family: raleway;
  text-align: start;
  font-size: calc(1.2rem + .3vw);
  font-weight: 600;
  margin: 0;
}
 
.submitBtn{
  transition: background-color 0.5s ease, color 1s ease;
}
.submitBtn:hover{
  background-color: #fff !important;
  color: #000 !important;
}


@media screen and (max-width: 980px) {
  .bigContainer{
    display: flex;
    flex-direction: column-reverse;
}
.billingDetails{
  width: 100%;
  margin-top: 1em;
}
.total_con{
  width: 100%;
  margin: auto;
}
}
