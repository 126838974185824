
.bigContainer{
    position: relative;
    width: 88%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 5vh 0 ;
    max-width: 1900px;
    margin: 0 auto;
  }

  .header{
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
  }
  
  .border{
    position: relative;
    width: 50%;
    height: fit-content;
    border: 3px #000 solid;
    border-radius: 1em;
    margin: 0 auto;
  }
  
  .title{
    position: relative;
    width: fit-content;
    margin: auto;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(1.2rem + .3vw);
    text-align: center;
  }
  .title h1{
    font-size: calc(1.8rem + .5vw);
  } 
  .out_of_stock{
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(194, 194, 194, 0.5);
    z-index: 9;
    cursor: auto;
    cursor: pointer;
    }
    
    .out_of_stock p {
    width: fit-content;
    height: fit-content;
    margin: auto;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.3vw);
    text-align: center;
    justify-self: center;
    align-self: center;
    color: #fff;
    padding: 0.2em 0.8em;
    border-radius: 5px;
    background-color: #111;
    }
  .cardsContainer{
      position: relative;
      width: 100%;
      display: grid;
      grid-template-columns: 23% 23% 23% 23%;
      gap: 3.3%;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin: 3em auto;
  }

  .card_container{
    width: 100%;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.2vw);
    letter-spacing: 0.01rem;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease-in-out;
    border: 1px solid #000;
}
.cardsContainerMob{
  position: relative;
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.card_container:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    
  }
.card_img{
    position: relative;
    width: 90%;
    max-height: 18em;
    height: 18vw !important;
    margin: 5% auto;
    background-color: #000;
}

.img{
  object-fit: cover !important;
  height: 100%;
  width: 100%;
}

.addContainer{
  position: absolute;
  font-size: calc(.7rem + .2vw);
  width: 100%;
  bottom: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: .5em 0 .7em 0;
  opacity: 0;
  z-index: 9;
  transition: opacity 0.3s ease-in-out;
}

.card_container:hover .addContainer{
  opacity: 1;
}

.card_content{
    width: 90%;
    margin: auto;
    text-align: start;
    font-size: calc(0.8rem + 0.2vw);
    padding-bottom: 5%;
}
.swiperslide{
  margin: 3em 0 3em 0;
  border: 1px solid #000;
  height: auto !important;
}



/* @media screen and (max-width:950px) {
  .cardsContainer{
    grid-template-columns: 31.5% 31.5% 31.5%;
    gap: 2.75%;
}
.card_img{
  height: 24vw !important;
}
} */

@media screen and (max-width:750px) {
  .cardsContainer{
    display: none;
  }
  .cardsContainerMob{
    display: flex;
  }
  .addContainer{
    position: relative;
    height: fit-content;
    opacity: 1;
    padding: 0 0 .5em 0;
  }
  .card_img{
    position: relative;
    width: 90%;
    height: fit-content !important;
    margin: 5% auto;
    background-color: #000;
  }
  .img{
  object-fit: cover !important;
  height: 27vw;
  width: 100%;
  }

  .card_container{
    border: none;
  }
  
.card_container:hover {
  transform: scale(1);
  box-shadow: none;
  
}
}

@media screen and (max-width:650px) {
  .img{
    height: 32vw !important;
}
}
