.cartsidebar{
    width: fit-content;
    max-width: 100vw;
    padding: 0 5%;
    background-color: white;
}

.header{
    position: relative;
    width: 100%;
    margin: 0 ;
    text-align: start;
  }
  
  .border{
    position: relative;
    width: 50%;
    height: fit-content;
    border: .2em #000 solid;
    border-radius: 1em;
    margin: 0;
  }
  
  .title{
    position: relative;
    width: fit-content;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(.7rem + .2vw);
    text-align: start;
  }
  .closeBtn{
    position: absolute;
    top: 1em;
    right: 0;
    border: none;
    background-color:  transparent;
    cursor: pointer;
  }

.btn_con{
    width: 90%;
    margin: 0 auto;
}
.btn{
    font-family: Raleway;
    font-size: calc(0.8rem + 0.3vw);
    width: 100%;
    background-color:#000;
    color:#fff;
    margin: 1em 0 3em 0;
    padding: .9em 0%;
    font-weight: 600;
    border: .1em solid #000;
    transition: background-color 0.5s ease, color 1s ease;
    cursor: pointer;
}

.btn:hover{
    background-color:#fff;
    color:#000;
}

.card{
    position: relative;
    width: 90%;
    height: fit-content;
    display: grid;
    grid-template-columns: 20% 66% 10%;
    gap: 2%;
    justify-content: space-between;
    padding: 2% 0;
    color: #000;
    border-bottom: 1px solid #8A8A8A;
    margin: 0 auto;
    
}

.imageCont{
    width: 100%;
    flex: 1;
}

.card p{
    margin: 0.4em 0;
}

.deleteCon{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: calc(0.7rem + 0.3vw);
    font-family: Raleway;
}

.quantity{
    width: 40%;
    height: fit-content;
    margin: auto 0;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    border: .1em solid #000;
    font-size: calc(.8rem + .3vw);
    margin: 0 0 0 auto;
  }

.delete_btn{
    width: fit-content;
    border: none;
    color: #fff !important;
    transition: background-color 0.5s ease, color 1s ease;
    font-size: calc(0.7rem + 0.3vw);
    border-radius: .7em;
    padding: 0.5em .6em;
    margin-left: auto;
    cursor: pointer;
}

.delete_btn:hover{
    background-color: red !important;

}
.dicription{
    width: 1005;
    color: #8A8A8A;
    font-size: calc(0.6rem + 0.2vw);
}
.price{
    color: #000;
    font-size: calc(0.7rem + 0.3vw);
}

.font_size{
    font-size: calc(0.7rem + 0.3vw);
}


@media screen and (max-width: 650px) {
   
    .card{
        grid-template-columns: 30% 51% 15%;
        padding: .9em 0 !important;        
    }
}