.featuredContainer{
    width: 100%;
    max-width: 1900px;
    margin: 2em auto;
    height: fit-content;
}

.content{
    display: grid;
    width: 90%;
    grid-template-columns: 32% 32% 32%;
    column-gap: 2%;
    margin: 0 auto;
}

.imageCont{
    position: relative;
    width: 100%;
    height: fit-content;
  }
  
  .imageCont img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .shopBtn{
    position: absolute;
    bottom: 3%;
    left: 5%;
    cursor: pointer;
    text-decoration: underline;
    font-family: Raleway;
    font-weight: 700;
    color: #fff;
    font-size: calc(.7rem + 0.2vw);
    letter-spacing: .1em;
  }
  

@media screen and (max-width:650px) {
    .heru{
        margin: 0em auto 0em auto;
    }
    .content{
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
    .imageCont{
        width: 80%;
        margin: auto;
      }
    
}

@media screen and (max-width:500px) {
    .imageCont{
        width: 100%;
        margin: auto;
      }
    
}