.heru{
    width: 100%;
    max-width: 1900px;
    margin: 10em auto 2em auto;
    height: fit-content;
}

.imageCont{
    width: 100%;
    height: fit-content;
  }
  
  .imageCont img{
    width: 100%;
    height: auto;
    min-height: 15em;
    object-fit: cover;
    filter: grayscale(100%);
  }
  

@media screen and (max-width:950px) {
    .heru{
        margin: 7em auto 2em auto;
    }
}